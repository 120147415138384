export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const ENQUIRE_FORM_REQUEST = 'ENQUIRE_FORM_REQUEST';
export const ENQUIRE_FORM_SUCCESS = 'ENQUIRE_FORM_SUCCESS';
export const ENQUIRE_FORM_ERROR = 'ENQUIRE_FORM_ERROR';

export const RESET_ENQUIRE_FORM_REQUEST = 'RESET_ENQUIRE_FORM_REQUEST';
export const RESET_REGISTRATION_REQUEST = 'RESET_REGISTRATION_REQUEST';

export const NOTIFY_FORM_REQUEST = 'NOTIFY_FORM_REQUEST';
export const NOTIFY_FORM_SUCCESS = 'NOTIFY_FORM_SUCCESS';
export const NOTIFY_FORM_ERROR = 'NOTIFY_FORM_ERROR';
