import styled from 'styled-components';
import * as variables from '../../../Style/__variables';
export const Button = styled.div`
  &:hover {
    color: ${variables.white};
  }
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.57px;
  text-align: center;
  background-color: ${(props) =>
    props.color ? variables[props.color] : variables.team_list_name};
  color: ${variables.white};
  font-size: 16px;
  border-radius: 1px;
  padding: 16px 30px;
`;
