import React from 'react';
import './dropdown.scss';
import { Dropdown, Form } from 'react-bootstrap';
function DropDownList({
  classnames,
  areaOfInterestList,
  optionClassname,
  handelSelectValue,
  type,
  optValue,
}) {
  return (
    <div className='dropdown-wrapper'>
      <Dropdown>
        <Dropdown.Toggle
          variant='muted'
          className='bg-white rounded-0 w-100 py-2 d-flex justify-content-between align-items-center'
          id='dropdown-basic'
        >
          <span className='ml-0'>{optValue}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className={classnames}>
          {areaOfInterestList.map((val, key) => (
            <Dropdown.Item
              className={optionClassname}
              value={val?.value}
              key={key}
              onClick={(e) => handelSelectValue(val, type)}
            >
              {val?.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
export default DropDownList;
