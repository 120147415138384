import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './Pages';
import InteriorDesign from 'src/Pages/InteriorDesign';
import { PATH } from 'src/constants/paths';
import AudioVisualService from 'src/Pages/AudioVisualService';
import BuildServices from 'src/Pages/BuildServices';
import BuildingConsultancy from './Pages/BuildingConsultancy';
import FurnitureServices from './Pages/FurnitureServices';
import CaseStudies from './Pages/CaseStudies';
import HumanExperience from './Pages/HumanExperience';
import BusinessExcellence from './Pages/BusinessExcellence';
import Policy from './Pages/Policy/index';
import Prosek from './Pages/CasestudiesItemone/Prosek';
import Lovecraft from './Pages/CasestudiesItemone/Lovecraft';
import Eb7 from './Pages/CasestudiesItemone/Eb7';
import Solagroup from './Pages/CasestudiesItemone/Solagroup';
import Privacy from './Pages/Privacy';
import Hive from './Pages/CasestudiesItemone/Hive';

function Routers() {
  return (
    <Router>
      <Switch>
        <Route exact path={PATH.HOME} component={Home} />
        <Route exact path={PATH.INTERIOR_DESIGN} component={InteriorDesign} />
        <Route
          exact
          path={PATH.AUDIO_VISUAL_SERVICE}
          component={AudioVisualService}
        />
        <Route exact path={PATH.BUILD_SERVICE} component={BuildServices} />
        <Route
          exact
          path={PATH.FURNITURE_SERVICES}
          component={FurnitureServices}
        />
        <Route exact path={PATH.CASE_STUDIES} component={CaseStudies} />
        <Route exact path={PATH.HUMAN_EXPERIENCE} component={HumanExperience} />
        <Route
          exact
          path={PATH.BUSINESS_EXCELLENCE}
          component={BusinessExcellence}
        />
        <Route
          exact
          path={PATH.BUILDING_CONSULTANCY}
          component={BuildingConsultancy}
        />
        <Route exact path={PATH.PRIVACY} component={Privacy} />
        <Route exact path={PATH.POLICY} component={Policy} />
        <Route exact path={PATH.CASE_STUDIES_FIRST} component={Prosek} />
        <Route exact path={PATH.CASE_STUDIES_SECOND} component={Lovecraft} />
        <Route exact path={PATH.CASE_STUDIES_THIRD} component={Eb7} />
        <Route exact path={PATH.CASE_STUDIES_FOURTH} component={Solagroup} />
        <Route exact path={PATH.CASE_STUDIES_FIFTH} component={Hive} />

        {/* <Route path='*' component={Home} /> */}
      </Switch>
    </Router>
  );
}
export default Routers;
