import { Button } from './styledComponent';

const GenericButton = (props) => {
  const { className, color, onClick } = props;
  return (
    <Button className={`${className} btn`} onClick={onClick} color={color}>
      {props.children}
    </Button>
  );
};
export default GenericButton;
