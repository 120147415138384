export const PATH = {
  HOME: '/',
  INTERIOR_DESIGN: '/approach',
  AUDIO_VISUAL_SERVICE: '/audio-visual-services',
  BUILD_SERVICE: '/build-services',
  FURNITURE_SERVICES: '/furniture-services',
  CASE_STUDIES: '/clients',
  HUMAN_EXPERIENCE: '/human-experience',
  BUSINESS_EXCELLENCE: '/business-excellence',
  BUILDING_CONSULTANCY: '/building-consultancy',
  CONFIRMATIONSCREEN: '/confirmation',
  PRIVACY: '/privacy',
  POLICY: '/terms',
  CASE_STUDIES_FIRST: '/clients/prosek',
  CASE_STUDIES_SECOND: '/clients/lovecraft',
  CASE_STUDIES_THIRD: '/clients/eb7',
  CASE_STUDIES_FOURTH: '/clients/solagroup',
  CASE_STUDIES_FIFTH: '/clients/hive',
};
