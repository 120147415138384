export const areaOfInterestList = [
  { name: "Workplace Strategy", value: "workplaceStrategy" },
  { name: "Furniture & Interiors", value: "furnitureInterious" },
  { name: "T.E.A.M", value: "team" },
];

export const enquiryForList = [
  { name: "Approach", value: "approach" },
  { name: "Business Excellence", value: "businessExcellence" },
  { name: "Human Experience", value: "humanExperience" },
];

export const enquiryForListFi = [
  { name: "Furniture Services", value: "furnitureServices" },
  { name: "Audio Visual", value: "audioVisual" },
  { name: "Build", value: "build" },
];

export const enquiryForListteam = [
  { name: " People Services", value: " peopleServices" },
  { name: " People Management", value: " peopleManagement" },
  { name: " Candidate Registration", value: " candidateRegistrations" },
  { name: " Work at REESA", value: " WorkatReesa" },
];
