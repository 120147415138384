import * as actions from '../action';
import fireAjax from '../../services/fireAjax';
import { call, put } from 'redux-saga/effects';

export function* registrationData(action) {
  try {
    const response = yield call(
      fireAjax,
      'POST',
      // `/api/sendEmail?type=query`,
      `${process.env.REACT_APP_BASE_URL}/api/sendEmail?type=query`,
      {},
      action.payload
    );
    if (response.statusText === 'Accepted') {
      yield put(actions.registerSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.registerError(e.response));
  }
}
