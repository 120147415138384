import styled, { css } from "styled-components";
import * as variables from "../../Style/__variables";

const image_common = css`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
`;

const selector_common = css`
  content: "";
  position: absolute;
  width: 4px;
  z-index: -1;
  background-color: ${(props) => variables[props.theme.color]};
  @media (max-width: 576px) {
    content: none;
  }
`;

const spacing_left = css`
  padding-right: 4vw;
`;
const spacing_right = css`
  padding-left: 4vw;
`;

export const TimelineWrapper = styled.div`
  position: relative;
`;

export const Image1 = styled.div`
  position: relative;
  max-height: 731px;

  @media (max-width: 576px) {
    margin-bottom: 30px;
  }
`;

const WrapperContainer = css`
  max-width: 1240px;
  margin: auto;
  padding-right: 22px;
  padding-left: 22px;
`;

export const Section1 = styled.div`
  ${WrapperContainer}
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }
  .timeline_circle_wrapper {
    padding: 95px 0;
    @media (max-width: 576px) {
      padding: 54px 0;
    }
  }
  .section2 {
    padding-top: 81px;

    @media (max-width: 576px) {
      padding: 0 0 80px 0;
    }
  }
`;

export const Image2 = styled.div`
  max-width: 265px;
  width: 100%;
  @media (max-width: 576px) {
    width: 100%;
    margin-bottom: 30px;
    max-width: 100%;
  }
  ${image_common}
  position: relative;

  &::after {
    ${selector_common}
    left: 100%;
    top: 50%;
    width: 16vw;
    height: 4px;
  }
  &::before {
    ${selector_common}
    content: "";
    position: absolute;
    width: 4px;
    background-color: ${(props) => variables[props.theme.color]};
    left: 50%;
    top: -500px;
    width: 4px;
    height: 500px;
  }
`;
export const Image7 = styled.div`
  width: 25vw;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 62px;
    background-color: ${(props) => variables[props.theme.color]};
    bottom: -62px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media (max-width: 576px) {
    width: 100%;
    &::after {
      content: none;
    }
  }
`;

export const Image3 = styled.div`
  @media (max-width: 576px) {
    width: 100%;
  }
  ${image_common}
  position: relative;

  &::after {
    ${selector_common}
    left: -6vw;
    top: 50%;
    width: 6vw;
    height: 4px;
  }
`;

export const Section3 = styled.div`
  padding-top: 143px;
  position: relative;
  .image_4 {
    width: 100%;
    height: 30vw;
    ${image_common}
  }
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }
  @media (max-width: 576px) {
    padding: 0 20px;
  }
`;

export const Section4 = styled.div`
  ${WrapperContainer}
  & > .row {
    padding-bottom: 150px;
    @media (max-width: 576px) {
      padding-top: 213px;
      padding-bottom: 0;
    }
  }
  .step2Hedaing {
    padding: 80px 0 80px 0;
    min-height: 220px;
    display: flex;
    align-items: center;
  }

  .img-section-2 {
    max-height: 730px;
    max-width: 530px;
  }
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }
  position: relative;
`;

export const Section5 = styled.div`
  ${WrapperContainer}
  padding-top:213px;
  padding-bottom: 150px;
  position: relative;
  &.bottomSpacingPersonTwoSection {
    padding-top: 213px;
    padding-bottom: 62px;
  }
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }

  .image_6 {
    width: 100%;
    max-width: 530px;
    max-height: 730px;
  }
  @media (max-width: 576px) {
    padding-bottom: 0;
    .image_6 {
      margin-bottom: 30px;
    }
  }
`;
export const Section6 = styled.div`
  ${WrapperContainer}
  & > .row {
    padding: 0 0 150px 0;
    @media (max-width: 576px) {
      padding-top: 213px;
      padding-bottom: 0;
    }
  }
  .step4Hedaing {
    padding: 85px 0 85px 0;
    display: flex;
    align-items: center;
  }
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }
  position: relative;
  .image_5 {
    width: 100%;
    max-height: 730px;
    max-width: 530px;
  }
`;

export const Section7 = styled.div`
  ${WrapperContainer}
  & > .row {
    padding-top: 213px;
    padding-bottom: 62px;
  }
  .col-left {
    ${spacing_left}
  }
  .col-right {
    ${spacing_right}
  }
  position: relative;
  .image_5 {
    width: 100%;
    max-height: 730px;
    ${image_common}
  }
  @media (max-width: 576px) {
    .image_5 {
      margin-bottom: 30px;
    }
  }
`;

const BorderContainer = css`
  height: 100%;
  position: absolute;
  border-bottom: 4px solid ${(props) => variables[props.theme.color]};
  z-index: -1;
  width: ${(props) =>
    props.theme?.borderContainerWidth
      ? props.theme?.borderContainerWidth
      : "70%"};
  left: 6%;
  @media (max-width: 576px) {
    border: none;
    width: 100%;
    left: 0;
    padding: 0 22px;
  }
`;

export const BorderContainerOne = styled.div`
  ${BorderContainer}
  top: 0;
  border-right: 4px solid
    ${(props) =>
      props.theme.color ? variables[props.theme.color] : variables.greeny_blue};
  .step-two {
    position: relative;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 213px;
    @media (max-width: 576px) {
      flex-direction: row-reverse;
    }
  }
`;

export const BorderContainerTwo = styled.div`
${BorderContainer}
  &.personTwoImageSection {
    height: 88%;
  }
  top: 0;
  border-left: 4px solid ${(props) => {
    return variables[props.theme.color];
  }};
  .step-three {
    position: relative;
    left: 0;
    background-color: white;
    display: flex;
    align-items: center;
    height: 213px;
    justify-content: flex-start;
  }
}
`;

export const BorderContainerThree = styled.div`
  ${BorderContainer}
  border-right: 4px solid ${(props) => variables[props.theme.color]};
  top: 0;
  .step-four {
    position: relative;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 213px;
    @media (max-width: 576px) {
      flex-direction: row-reverse;
    }
  }
`;

export const BorderContainerFour = styled.div`
  ${BorderContainer}
  border-left: 4px solid ${(props) => variables[props.theme.color]};
  top: 0;
  &.personTwoImageSection {
    height: 88%;
  }
  &.noEnd {
    border-bottom: none;
  }
  .step-5 {
    position: relative;
    left: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 213px;
  }
`;

export const BorderContainerFive = styled.div`
  border-right: 4px solid ${(props) => variables[props.theme.color]};
  height: 100%;
  position: absolute;
  z-index: -1;
  width: ${(props) =>
    props.theme.borderContainerWidth
      ? props.theme.borderContainerWidth
      : "70%"};
  left: 6%;
  top: 0;
`;
