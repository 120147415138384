import React, { Fragment, useState } from "react";
import NavBar from "src/Component/Navbar/Navbar";
import Header from "src/Component/common/header";
import BgImage from "src/assets/images/interior-design.jpg";
import Card from "src/Component/InteriorDesign/card";
import InteriorCarousel from "src/Component/InteriorDesign/carousel";
import Timeline from "../../Component/timeline";
import "./index.scss";
import Footer from "../../Component/Footer/Footer";
import {
  interiorDesignData1,
  interiorDesignData2,
  interiorDesignData3,
  interiorDesignData4,
  interiorDesignData5,
} from "src/constants/data";
import VisionImage from "src/assets/images/vision.svg";
import PersonImage from "src/assets/images/Person1_interior.jpg";
import RoundTable from "src/assets/images/roundtable.svg";
import SoofaImage from "src/assets/images/soofa.svg";
import ChairImage from "src/assets/images/chair.svg";
import OfficeChairImage from "src/assets/images/OfficeChair.svg";
import OfficeFurniture from "src/assets/images/furnitureImage.jpg";
import OfficeFurniture2 from "src/assets/images/furniture.svg";
import PersonImage2 from "src/assets/images/Person2.jpeg";
import SubHeader from "../../Component/SubHeader";
import formBg from "../../assets/formBg.jpg";

const InteriorDesign = (props) => {
  const [activeIndex, setIndex] = useState(0);
  const handleSelect = (index) => {
    setIndex(index);
  };
  return (
    <Fragment>
      <NavBar history={props.history} />
      {/* <Header title="Interior Design" image={BgImage} /> */}
      <Header title="Approach" image={BgImage} />
      <SubHeader
        heading="How it works"
        subHeading={`We are a unique creative team who thrive on 
        designing interiors and products that make our clients feel 
        truly inspired, comfortable and energised`}
      />
      <div className="main_card_wrapper">
        <Card handleSelect={handleSelect} activeIndex={activeIndex} />
      </div>
      <InteriorCarousel activeIndex={activeIndex} handleSelect={handleSelect} />
      <Timeline
        totalSteps={5}
        timelineData1={interiorDesignData1}
        timelineData2={interiorDesignData2}
        timelineData3={interiorDesignData3}
        timelineData4={interiorDesignData4}
        timelineData5={interiorDesignData5}
        image1={VisionImage}
        image2={PersonImage}
        image3={RoundTable}
        image4={SoofaImage}
        image5={ChairImage}
        image6={OfficeChairImage}
        image7={OfficeFurniture}
        image8={OfficeFurniture2}
        // image9={PersonImage2}
        formBg={formBg}
        borderContainerFourClassName={"noEnd"}
        // person2ImageStep={5}
        themeColor="greeny_blue"
      />
      <Footer />
    </Fragment>
  );
};

export default InteriorDesign;
