import { takeLatest, all } from 'redux-saga/effects';
import * as constants from './constant';
import { registrationData } from './registration/action';
import { enqueryForm } from './enqueryForm/action';
import { notifyForm } from './notifyForm/action';

function* watchActions() {
  yield takeLatest(constants.REGISTER_REQUEST, registrationData);
  yield takeLatest(constants.ENQUIRE_FORM_REQUEST, enqueryForm);
  yield takeLatest(constants.NOTIFY_FORM_REQUEST, notifyForm);
}

export default function* rootSaga() {
  yield all([watchActions()]);
}
