import React from "react";
import LOGO_2020 from "../../assets/LOGO_2020.svg";
import instagram from "../../assets/instagram.svg";
import linkedin from "../../assets/linkedin.svg";
import rics from "../../assets/rics.png";
import Typography from "../../Component/Typography";
import { Link } from "react-router-dom";
import { PATH } from "../../constants/paths";
import { AddressSection, Copyright, FooterSection } from "./styledComponent";

function Footer(props) {
  const { fullScreen } = props;

  return (
    <FooterSection
      className="px-5 py-4 d-flex flex-column justify-content-between"
      fullScreen={fullScreen ? fullScreen : false}
    >
      <div>
        <div className="footer_logo">
          <img src={LOGO_2020} alt="LOGO_2020" />
        </div>
        <div className="row pt-5">
          <AddressSection className="col-lg-8 col-sm-12">
            <div className="row">
              <div className="col-6 col-md-4 col-xl-4 col-sm-4 col-xs-4 mb-0 north_address">
                <Typography
                  variant="regular"
                  size="xxxSmall"
                  className="north_para_one"
                >
                  Longbow House <br /> 1st floor <br />
                  20 Chiswell Street
                  <br /> London, EC1Y 4TW
                </Typography>

                <Typography
                  variant="regular"
                  size="xxxSmall"
                  className=" north_para_one"
                >
                  <span style={{ color: "white" }}>
                    0330 004 0404 &nbsp;&nbsp;&nbsp;
                    <u>info@reesa.com</u>
                  </span>
                </Typography>
              </div>
              <div className="col-6 col-md-4 col-xl-4 col-sm-4 col-xs-4 mb-5 north_address">
                <div className="d-flex">
                  <Typography
                    variant="regular"
                    className="vertical_line mr-0 mr-sm-5"
                  ></Typography>
                  <div className="d-flex justify-content-end"></div>
                </div>
              </div>
            </div>
          </AddressSection>
        </div>
      </div>
      <Copyright className="row mt-4 pt-4 copy_right">
        <div className="col-lg-6 ">
          <Typography
            size="xxxSmall"
            variant="regular"
            className=" copy_right_text"
          >
            © 2025 - All Rights Reserved
            <span className="px-3"> |</span>{" "}
            <Link className="north_para" to={PATH.PRIVACY}>
              <span style={{ color: "white" }} className="north_para_two">
                {" "}
                Privacy Policy
              </span>
            </Link>{" "}
            <span className="px-3">| </span>{" "}
            <Link className="north_para" to={PATH.POLICY}>
              <span style={{ color: "white" }} className="north_para_two">
                Terms and Conditions
              </span>
            </Link>
          </Typography>
          <Typography
            size="xxxSmall"
            variant="regular"
            className="pt-2 copy_right_text"
          >
            REESA is a trading name of Row Group Limited 
          </Typography>
        </div>
        <div className="col-lg-6">
          <div className="d-flex justify-content-end social_icons">
            <div className="d-flex justify-content-between">
              <img
                className="px-3 cursor-pointer"
                src={instagram}
                alt="instagram"
                onClick={() =>
                  window.open("https://www.instagram.com/reesa.uk/")
                }
              />
              <img
                className="px-3 cursor-pointer"
                src={linkedin}
                alt="linkedin"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/reesa-property-and-people/"
                  )
                }
              />

              <img className="px-3" width="180px" src={rics} alt="rics" />
            </div>
          </div>
        </div>
      </Copyright>
    </FooterSection>
  );
}
export default Footer;
