import React, { Fragment } from 'react';
import NavBar from 'src/Component/Navbar/Navbar';
import Header from 'src/Component/common/header';
import Timeline from 'src/Component/timeline';
import {
  projectManagementData1,
  projectManagementData2,
  projectManagementData3,
} from 'src/constants/projectManagment';
import PM1 from 'src/assets/ProjectManagement/PM1.jpg';
import PM2 from 'src/assets/ProjectManagement/PM2.jpg';
import PM3 from 'src/assets/ProjectManagement/PM3.jpg';
import PM4 from 'src/assets/ProjectManagement/PM4.jpg';
import PM5 from 'src/assets/ProjectManagement/PM5.jpg';
import PM6 from 'src/assets/ProjectManagement/PM6.jpg';
import PM7 from 'src/assets/ProjectManagement/PM7.jpg';
import PMLady from 'src/assets/ProjectManagement/PMLady.jpg';

import SubHeader from '../../Component/SubHeader';
import Footer from '../../Component/Footer/Footer';

const BuildingConsultancy = (props) => {
  return (
    <Fragment>
      <NavBar history={props.history} />
      <Header title='Building Consultancy' image={PM1} />
      <SubHeader
        showBar={true}
        themeColor='darkPink'
        subHeading={
          'Chartered Surveyors providing Project Management, Surveys and Real Estate Building Services'
        }
      />
      <Timeline
        totalSteps={3}
        borderContainerTwoClassName={'border-bottom-0'}
        timelineData1={projectManagementData1}
        timelineData2={projectManagementData2}
        timelineData3={projectManagementData3}
        image1={PM2}
        image2={PMLady}
        image3={PM3}
        image4={PM4}
        image5={PM5}
        image6={PM6}
        formBg={PM7}
        themeColor='darkPink'
      />
      <Footer />
    </Fragment>
  );
};

export default BuildingConsultancy;
