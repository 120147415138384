export const furnitureServicesData1 = {
  content: [
    {
      title: "Furniture Procurement",
      description:
        "Working with us you’ll discover through our consultative approach the products available to enhance your space and match your specific requirements. Truly independent advisors with free access to the market.",
    },
    {
      title: "Working to your Budget",
      description:
        "Our clients benefit from a dedicated team with a single point of contact throughout the whole life cycle of a project; from procurement stage to delivery and installation. This focused approach means your budget and best interests are always paramount.",
    },
    {
      title: "Showroom Visits & Samples",
      description:
        "We can organise samples of most products to a clients office and always recommended visiting the showrooms where possible.",
    },
    {
      title: "INTERIOR DESIGN",
      description:
        "We offer <a href='/approach' target='blank'>interior design services </a> to bring your office to life.",
    },
  ],
  heading: "Product selection",
};

export const furnitureServicesData2 = {
  content: [
    {
      title: "Specifying",
      description:
        "Once you’ve picked your preferred products and started developing your project scope REESA will specify in every detail your solution.",
    },
    {
      title: "Quoting and Order",
      description:
        "We have developed online tools to provide you with easy to understand quotes and an ordering process.",
    },
    {
      title: "Project Management ",
      description:
        "In-house operations support team and site supervisors minimise the risk involved in furniture delivery and installation, irrespective of project size or location. ",
    },
    {
      title: "Relocation & Move Management ",
      description:
        "As required our installation teams help move existing furniture into a new site and help with any office reconfigurations or changes.",
    },
  ],
  heading: "Order fulfilment ",
};

export const furnitureServicesData3 = {
  content: [
    {
      title: "Maintenance",
      description:
        "Quality products always benefit from ongoing maintenance which our after team will provide.",
    },
    {
      title: "Ergonomics Training",
      description:
        "People require training and support to ensure that they products are set up correctly and working as intended.",
    },
    {
      title: "Warranties ",
      description: " We will be your constant partner for warranty support.",
    },
    {
      title: "Top Up Orders",
      description:
        "You will have access to an online account making top up order simple and hassle free. ",
    },
  ],
  heading: "After care ",
};
