import React from "react";
import healthCare from "../../../assets/HealthCare.png";
import industrial from "../../../assets/Industrial.png";
import office from "../../../assets/Office.png";
import retail from "../../../assets/Retail.png";
import "./propertytype.scss";
import Typography from "../../Typography";

function PropertyType() {
  return (
    <div className="property_type element " name="test1">
      <div className="">
        <Typography
          className="property_heading"
          variant="extraBold"
          size="xxxxLarge"
          mobileSize="smLarge"
        >
          For all types of <br />
          commercial property
        </Typography>
        <div
          className="row flex-wrap justify-content-between  pt-5 property_list"
          name="firstInsideContainer"
        >
          <div className="office col-sm-3 px-4">
            <img className="office_img w-100" src={office} alt="office" />
            <Typography
              className="office_text text-center pt-3 heading-title "
              variant="regular"
              size="xxMedium"
              mobileSize="small"
            >
              Office
            </Typography>
          </div>
          <div className="office col-sm-3 px-4">
            <img
              className="office_img w-100"
              src={industrial}
              alt="industrial"
            />
            <Typography
              className="office_text text-center pt-3 heading-title "
              variant="regular"
              size="xxMedium"
              mobileSize="small"
            >
              Industrial
            </Typography>
          </div>
          <div className="office col-sm-3 px-4">
            <img className="office_img w-100" src={retail} alt="retail" />
            <Typography
              className="office_text text-center pt-3 heading-title "
              variant="regular"
              size="xxMedium"
              mobileSize="small"
            >
              Hospitality
            </Typography>
          </div>
          <div className="office col-sm-3 px-4">
            <img
              className="office_img w-100"
              src={healthCare}
              alt="healthCare"
            />
            <Typography
              className="office_text text-center pt-3 heading-title "
              variant="regular"
              size="xxMedium"
              mobileSize="small"
            >
              Healthcare
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PropertyType;
