import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from '../../assets/reesa_logo.svg';
import toggle_icon from '../../assets/menu_icon.svg';
import './navbar.scss';
import phone from '../../assets/phone.svg';
import { ReactComponent as Compass } from '../../assets/compass/compass-icon.svg';
import search from '../../assets/search.svg';
import shopping_cart from '../../assets/shopping-cart.svg';
import { Typo } from '../Typography/styledComponent';
import Typography from '../Typography';

function HomeNavbar(props) {
  const [show, setShow] = useState({
    workPlaceStrategy: false,
    realEstate: false,
    furniture: false,
  });
  return (
    <div>
      <div className='fixed_header bg-white'>
        <Navbar collapseOnSelect expand='lg'>
          <Link to='/'>
            <div className='d-flex flex-column'>
              <img
                src={logo}
                alt='logo'
                style={{ width: 153 + 'px', height: 45 + 'px' }}
              />
            </div>
          </Link>
          <Navbar.Toggle
            aria-controls='responsive-navbar-nav float-right'
            className='p-0 border-0'
          >
            <span className='navbar-toggle-icon text-white '>
              <img src={toggle_icon} alt='toggle_icon' />
            </span>
          </Navbar.Toggle>

          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mr-auto'></Nav>
            <div className='d-flex flex-column'>
              <div className='mr-3'>
                <Nav className='d-flex justify-content-end py-3 py-sm-0'>
                  <img
                    src={phone}
                    alt='phone'
                    className='mr-1 pr-1 nav_img_call'
                    style={{ maxWidth: 20 + 'px', maxHeight: 20 + 'px' }}
                  />
                  <span className='mt-0 pr-2 nav-text nav_call'>
                    0330 004 0404
                  </span>
                </Nav>
              </div>
              <div className='navbar_item_list'>
                <Nav className='mb-3'>
                  <div>
                    <NavDropdown
                      className='fixedflicker mx-5'
                      style={{ cursor: 'default' }}
                      title={<span className='text-white fordefault'>_</span>}
                    >
                      <NavDropdown.Item className='py-2' href=''>
                        {' '}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  <div className='workplace_list'>
                    <NavDropdown
                      title='Workplace Strategy'
                      id='basic-nav-dropdown'
                      className='workplace_list mx-5'
                      show={show.workPlaceStrategy}
                      onMouseEnter={() =>
                        setShow({ ...show, workPlaceStrategy: true })
                      }
                      onMouseLeave={() =>
                        setShow({ ...show, workPlaceStrategy: false })
                      }
                    >
                      <NavDropdown.Item
                        className='py-2 font-regular'
                        href=''
                        onClick={() => props.history.push('/approach')}
                      >
                        <Typography variant='regular' size='xxSmall'>
                          Approach
                        </Typography>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className='py-2'
                        href=''
                        onClick={() =>
                          props.history.push('/building-consultancy')
                        }
                      >
                        <Typography variant='regular' size='xxSmall'>
                          {' '}
                          Business Consultancy
                        </Typography>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className='py-2'
                        href=''
                        onClick={() =>
                          props.history.push('/business-excellence')
                        }
                      >
                        <Typography variant='regular' size='xxSmall'>
                          {' '}
                          Business Excellence
                        </Typography>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className='py-2'
                        href=''
                        onClick={() => props.history.push('/human-experience')}
                      >
                        <Typography variant='regular' size='xxSmall'>
                          Human Experience
                        </Typography>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  <div className='furniture_list'>
                    <NavDropdown
                      title='Furniture & Interiors'
                      id='basic-nav-dropdown'
                      className=' mx-3'
                      show={show.furniture}
                      onMouseEnter={() => setShow({ ...show, furniture: true })}
                      onMouseLeave={() =>
                        setShow({ ...show, furniture: false })
                      }
                    >
                      <NavDropdown.Item
                        className='py-2 pl-4 font-regular'
                        href=''
                        onClick={() =>
                          props.history.push('/furniture-services')
                        }
                      >
                        <Typography variant='regular' size='xxSmall'>
                          Furniture Services
                        </Typography>
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        className='py-2 font-regular'
                        href=''
                        onClick={() =>
                          props.history.push('/audio-visual-services')
                        }
                      >
                        <Typography variant='regular' size='xxSmall'>
                          Audio Visual
                        </Typography>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className='py-2 font-regular'
                        href=''
                        onClick={() => props.history.push('/build-services')}
                      >
                        <Typography variant='regular' size='xxSmall'>
                          Build
                        </Typography>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </Nav>
              </div>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}
export default HomeNavbar;
