import React, { useEffect } from "react";
import {
  HomeNavbar,
  Benfit,
  PropertyType,
  BusinessSpectrum,
  AdvisorsForm,
  HomeCarousel,
  Footer,
} from "../Component/HomePage";
import advisorImg from "../assets/advisor.jpg";
import "../jquery.pagepiling.css";
import "../jquery.pagepiling";

function Home(props) {
  return (
    <div>
      <HomeNavbar history={props.history} />
      <HomeCarousel />
      <Benfit />
      <PropertyType />
      <BusinessSpectrum />
      <AdvisorsForm bgImage={advisorImg} />
      <Footer />
    </div>
  );
}

export default Home;
