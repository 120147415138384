import { combineReducers } from 'redux';
import registrationReducer from './registration/reducer';
import enqueryFormReducer from './enqueryForm/reducer';
import notifyFormReducer from './notifyForm/reducer';

const rootReducer = combineReducers({
  registrationReducer: registrationReducer,
  enqueryFormReducer: enqueryFormReducer,
  notifyFormReducer: notifyFormReducer,
});

export default rootReducer;
